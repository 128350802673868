import {
  $name,
  type TimerName,
  type Timer,
} from './types.js'

export default class TimerRegistry {
  #timers: Map<TimerName, Timer<TimerName>>

  constructor() {
    this.#timers = new Map()
  }

  track(cancel: () => void, name: TimerName = Symbol('timer name')) {
    this.cancel(name)

    const timer: Timer<TimerName> = {
      name,
      cancel,
    }

    this.#timers.set(name, timer)

    return {
      [$name]: name,
      cancel: () => this.#cancel(timer),
    }
  }

  cancel(name: TimerName) {
    const timer = this.#timers.get(name)

    if (timer) {
      this.#cancel(timer)
    }
  }

  cancelAll() {
    this.#timers.forEach(timer => timer.cancel())
    this.#timers = new Map()
  }

  #cancel(timer: Timer<TimerName>) {
    timer.cancel()
    this.#timers.delete(timer.name)
  }
}
