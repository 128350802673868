export default function createRequiredContext<T>(name: string) {
  const Context = createContext(createGuard<T>(name))

  return [
    Context,
    function useRequiredContext() {
      return useContext(Context)
    },
  ] as const
}



function createGuard<T>(_name: string) {
  const guard = () => {
    // throw new Error(`${name} not attached`)
  }

  return new Proxy(guard, {
    get() {
      // throw new Error(`${name} not attached`)
    },
  }) as T
}