import useInDevMode from './hooks/useInDevMode.js'
import loader from '#/loader'
import useRenderContext from '#/hooks/useRenderContext'

type FlagApi = {
  toggle: (name: string, value?: boolean) => void
  flags: Set<string>
}

export const [FlagContext, useFlags] = createRequiredContext<FlagApi>('FlagContext')

export default function FlagProvider({ children }: { children: React.ReactNode }) {
  const inDevMode = useInDevMode()
  const context = useRenderContext()

  /*
  AsyncContext is severed when performing SSR so `dx.flags` is not properly set.
  I could use Vike's `passToClient` option and always pull it out of PageContext,
  but that would cause a *.pageContext.json request on every single navigation.

  Instead we'll pull it out of context during SSR, and use `dx.flags` (which is
  set in <head>) for hydration and navigation.
   */
  const [flags, setFlags] = useState(() => ('flags' in context ? context.flags : null) ?? dx.flags)
  const prevFlags = useRef(flags)

  const api = useMemo<FlagApi>(() => ({
    flags,
    toggle(name: string, value?: boolean) {
      setFlags(f => {
        const set = new Set(f)
        const next = value ?? !f.has(name)

        next ?
          set.add(name) :
          set.delete(name)

        return set
      })
    },
  }), [flags])


  useEffect(() => {
    if (!Object.isEqual(flags, prevFlags.current) && inDevMode) {
      prevFlags.current = flags

      const [load, abort] = loader<Dx.Api.Dev.Flags.In, never>('/api/dev/flags', {
        method: 'post',
        body: {
          names: Array.from(flags),
        },
      })

      load().catch(dx.capture)
      return abort
    }
  }, [flags, inDevMode])

  return (
    <FlagContext.Provider value={api}>
      {children}
    </FlagContext.Provider>
  )
}


export function useFlag(name: string) {
  const { flags } = useFlags()
  return flags.has(name)
}