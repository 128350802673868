export type Box<T> = T & { readonly ref: RefFn<T> }

type RefFn<T> = <K extends keyof T, A extends any[]>(name: K, ...args: A) => SetRefFn<T[K]>
type SetRefFn<V> = (value: Unwrap<V>) => void
type Unwrap<T> = T extends (...args: infer A) => any ? A[0] : T



type BoxProps<T> = T | ((api: BoxApi) => T)
type BoxApi = {
  <T>(): Dx.Ref.Resolve<T> | null
  <T>(initialValue: T): T
}

export default function useBox<T extends Record<string, any>>(initialValue: BoxProps<T>) {
  const ref = useRef<Box<T>>(null)
  return ref.current ??= createBox<T>(initialValue)
}


function createBox<T extends Record<string, any>>(initialValue: BoxProps<T>) {
  const data = typeof initialValue === 'function' ?
    initialValue((init = null) => init) :
    initialValue

  if (!Object.isObject(data)) {
    throw new Error('Boxes must be objects')
  }

  const refs: { [K in keyof T]?: SetRefFn<T[K]> } = {}

  const box: Box<T> = {
    ...data,
    ref: (name, ...args) => refs[name] ??= value => {
      (box as T)[name] = typeof box[name] === 'function' ?
        box[name](value, ...args) :
        value
    },
  }

  return box
}