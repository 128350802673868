import {
  hasAncestor,
  findAncestorMatching,
  findAncestor,
} from './traversal.js'

export function isActiveElement(element: Element) {
  return element === getActiveElement()
}

export function getActiveElement() {
  try {
    return (document.activeElement ?? document.body) as HTMLElement

  } catch (e) {
    return document.body
  }
}



export function blurActive() {
  return getActiveElement().blur()
}

export function blurActiveChild(parent?: Nullable<HTMLElement>) {
  const active = getActiveElement()

  if (parent && hasAncestor(active, parent)) {
    active.blur()
  }
}


export function canFocus(node: HTMLElement) {
  return Boolean(
    node.offsetWidth > 0 &&
    node.offsetHeight > 0 &&
    node.tabIndex !== -1 && (
      !('type' in node) ||
      node.type !== 'hidden'
    )
  )
}


const focusableSelector = [
  'a',
  'button',
  'input',
  'textarea',
  'select',
  '[tabindex]',
].join(', ')

const notFocusableSelector = [
  ':not([disabled])',
  ':not([tabindex="-1"])',
].join('')

export function focusNextElement(relativeTo: HTMLElement | null = getActiveElement()) {
  focusRelativeElement({
    offset: 1,
    relativeTo,
  })
}

export function focusPreviousElement(relativeTo: HTMLElement | null = getActiveElement()) {
  focusRelativeElement({
    offset: -1,
    relativeTo,
  })
}
  
type FocusRelativeElementOptions = {
  offset?: number
  relativeTo?: HTMLElement | null,
  parent?: HTMLElement
  parentSelector?: string
  selector?: string
  groupSelector?: string
} & FocusOptions

export function focusRelativeElement(options: FocusRelativeElementOptions = {}) {
  const {
    offset = 0,
    relativeTo = getActiveElement(),
    parent,
    selector = focusableSelector,
    groupSelector,
    preventScroll,
  } = options

  const context = getContext(options)

  if (!relativeTo || !context) {
    return
  }

  const targetSelector = selector
    .split(',')
    .map(segment => `${segment}${notFocusableSelector}`)
    .join(', ')

  let groupIndex = -1
  let unfocusable = new Set()

  if (groupSelector) {
    const nearest = findAncestorMatching(relativeTo, groupSelector)

    if (nearest) {
      const nodes = selectChildren(nearest, targetSelector)
      unfocusable = new Set(nodes)
      groupIndex = nodes.indexOf(relativeTo)
    }
  }

  const focusable = getFocusableNodes(parent)
  const index = focusable.indexOf(relativeTo)

  let focusNode: HTMLElement | null = null
  let nextIndex = index + offset
  let wraps = 0

  while (nextIndex !== index && wraps < 2) {
    if (nextIndex < 0) {
      nextIndex = focusable.length - 1
      wraps++

    } else if (nextIndex === focusable.length) {
      nextIndex = 0
      wraps++
    }

    const node = focusable[nextIndex]

    if (!unfocusable.has(node)) {
      focusNode = node
      break
    }

    nextIndex += offset
  }


  if (!focusNode) {
    return
  }

  if (groupSelector) {
    const group = findAncestorMatching(focusNode, groupSelector)

    if (group) {
      const siblings = selectChildren(group, selector)
      const sibling = siblings[Math.clamp(groupIndex, 0, siblings.length - 1)]

      if (sibling) {
        focusNode = sibling
      }
    }
  }

  focusNode.focus({ preventScroll })
}

export function getFocusableNodes(node: Document | HTMLElement | null = getActiveElement()) {
  return node ?
    selectChildren(node, focusableSelector).filter(canFocus) :
    []
}

export function focusFirstElement(node: Nullable<HTMLElement> = getActiveElement(), options: FocusOptions = {}) {
  const first = getFocusableNodes(node).first()
  
  if (first) {
    first.focus(options)
    return true
  }

  return false
}


function selectChildren(node: Document | Element, selector: string) {
  return Array.from(node.querySelectorAll<HTMLElement>(selector))
}


function getContext(options: FocusRelativeElementOptions) {
  const {
    relativeTo,
    parent,
    parentSelector,
  } = options

  if (relativeTo) {
    if (parent) {
      return findAncestor(relativeTo, parent)
    }

    if (parentSelector) {
      return findAncestorMatching(relativeTo, parentSelector)
    }
  }

  return null
}