import { useUser, useAuth as useClerkAuth } from '@clerk/clerk-react'


type SignedInState = {
  email: string | null
  firstName: string | null
  fullName: string | null
  imageUrl: string
  isLoaded: boolean
  isSignedIn: true
  lastName: string | null
  permissions: Set<string>
  signOut: () => Promise<void>
  userId: string
}

type SignedOutState = {
  email: null
  firstName: null
  fullName: null
  imageUrl: null
  isLoaded: boolean
  isSignedIn: false
  lastName: null
  permissions: Set<string>
  userId: null
  signOut: () => Promise<void>
}

type AuthState = SignedInState | SignedOutState

export default function useAuth(): AuthState {
  const {
    isLoaded = false,
    user = null,
  } = useUser()

  const {
    signOut,
  } = useClerkAuth()


  return useMemo(() => {
    if (user) {
      const email = user.primaryEmailAddress?.emailAddress ?? null

      return {
        isLoaded,
        isSignedIn: true,
        email,
        firstName: user.firstName,
        fullName: user.fullName,
        imageUrl: user.imageUrl,
        lastName: user.lastName,
        permissions: new Set(Array.cast(user.publicMetadata.permissions) as string[]),
        userId: user.id,
        signOut,
      }
    }

    return {
      isLoaded,
      isSignedIn: false,
      email: null,
      firstName: null,
      fullName: null,
      imageUrl: null,
      lastName: null,
      permissions: new Set<string>(),
      userId: null,
      signOut,
    }
  }, [isLoaded, user, signOut])
}