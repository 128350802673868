import TimerBuilder from './TimerBuilder.js'

/**
 * Returns an API to schedule and manage timers within React components.
 * 
 * Timers may be named with `named(name)` and later canceled with `cancel(name)`.
 * If a timer is given a name, and then later another timer is given the same name,
 * the original timer will be canceled before the new timer is scheduled.
 * 
 * All timers are automatically canceled when the component unmounts.
 */
export default function useTimers() {
  const api = useMemo(() => new TimerBuilder(), [])
  useEffect(() => () => api.cancelAll(), [api])

  return api
}
