export function findAncestor(node: Nullable<HTMLElement>, ancestor: Nullable<HTMLElement>) {
  // eslint-disable-next-line no-empty
  while (node && node !== ancestor && (node = node.parentElement)) {}
  return node
}

export function hasAncestor(node: Nullable<HTMLElement>, ancestor: Nullable<HTMLElement>) {
  return Boolean(findAncestor(node, ancestor))
}

export function hasAncestorMatching(node: Nullable<HTMLElement>, selector: string) {
  return Boolean(findAncestorMatching(node, selector))
}

export function findAncestorMatching(node: Nullable<HTMLElement>, selector: string) {
  // eslint-disable-next-line no-empty
  while (node && !node.matches(selector) && (node = node.parentElement)) {}
  return node
}

export function findAncestorsMatching(node: Nullable<HTMLElement>, selector: string) {
  const nodes: HTMLElement[] = []

  while (node) {
    if (node.matches(selector)) {
      nodes.push(node)
    }
    
    node = node.parentElement
  }

  return nodes
}