import createStore, { conditionalStore } from '#/store'
import useDevAuth from './useDevAuth.js'

type DevModeState = {
  isEnabled: boolean
  enable: () => void
  disable: () => void
  toggle: () => void
  panel: {
    isOpen: boolean
    open: () => void
    close: () => void
    toggle: () => void
  }
}

const store = createStore<DevModeState>(set => ({
  isEnabled: false,
  enable: () => set(s => s.isEnabled = true),
  disable: () => set(s => s.isEnabled = false),
  toggle: () => set(s => s.isEnabled = !s.isEnabled),
  panel: {
    open: () => set(s => s.panel.isOpen = true),
    close: () => set(s => s.panel.isOpen = false),
    toggle: () => set(s => s.panel.isOpen = !s.panel.isOpen),
    isOpen: false,
  },
}),
{
  persist: {
    key: 'dev:mode',
  },
})


export default conditionalStore(store, () => useDevAuth())