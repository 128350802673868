import {
  $registry,
  type TimerName,
} from './types.js'

import TimerRegistry from './TimerRegistry.js'
import NamedTimerBuilder from './NamedTimerBuilder.js'

export default class TimerBuilder extends NamedTimerBuilder<symbol> {
  constructor() {
    super(new TimerRegistry())
  }

  /**
   * Gives a name to the timer chained after this call. This name can later be
   * used with `cancel`.
   *
   * If another timer with this name was already scheduled, the original timer
   * will be canceled before this timer is scheduled.
   */
  name<N extends TimerName>(name: N) {
    return new NamedTimerBuilder(this[$registry], name)
  }

  /**
   * Cancel the timer with the given `name`.
   */
  cancel(name: TimerName) {
    return this[$registry].cancel(name)
  }

  /**
   * Cancels all active timers.
   */
  cancelAll() {
    return this[$registry].cancelAll()
  }
}
