type Args<T extends Nullable<UnknownFunction>> = T extends UnknownFunction ? Parameters<T> : []

export default function useEvent<F extends UnknownFunction>(fn: F, stub?: boolean): F
export default function useEvent<F extends Nullable<UnknownFunction>>(fn: F, stub: false): F
export default function useEvent<F extends Nullable<UnknownFunction>>(fn: F, stub?: true): NonNullable<F>
export default function useEvent<F extends Nullable<UnknownFunction>>(fn: F, stub = true) {
  const handler = useRef(fn)

  useInsertionEffect(() => {
    handler.current = fn
  })

  const callback = useCallback((...args: Args<NonNullable<F>>) => {
    if (typeof handler.current === 'function') {
      return handler.current(...args)
    }
  }, [])

  if (typeof fn === 'function') {
    return callback
  }

  if (stub) {
    return noop
  }

  return undefined
}