import type { Draft } from 'immer'
import { create, type StoreApi, type UseBoundStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { persist, createJSONStorage } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

export type Store<T extends object> = ReturnType<typeof createStore<T>>

type AnyStore = UseBoundStore<StoreApi<any>>
type BaseStore = AnyStore & {
  baseState: any
}

export type GetFn<T> = () => T
export type SetFn<T> = (fn: (state: Draft<T>) => unknown) => void
export type StateFn<T> = (set: SetFn<T>, get: GetFn<T>) => T

type CreateStoreOptions<T> = {
  persist?: {
    key?: string
    session?: boolean
    only?: Paths<T>[]
    omit?: Paths<T>[]
    filter?: (state: T) => Partial<T>
  }
}
export default function createStore<T extends object>(fn: StateFn<T>, options: CreateStoreOptions<T> = {}) {
  const state = immer<T>((set, get) => fn(set_ => set(s => void set_(s)), get))
  const baseState = fn(noop, () => baseState)

  if (options.persist?.key) {
    let didHydrate = false

    const {
      key,
      session,
      filter,
      omit,
      only,
    } = options.persist

    const storage = session ?
      () => sessionStorage :
      () => localStorage

    const partialize = (
      filter ??
      (only && (s => Object.pick(s, only))) ??
      (omit && (s => Object.omit(s, omit))) ??
      identity
    )

    const store = create<T>()(
      persist(state, {
        name: `dx:${key}`,
        storage: createJSONStorage(storage),
        partialize,
        merge: (persisted, current) => Object.merge(current, persisted) as unknown as T,
      })
    )

    const baseStore = withBaseState(store, baseState)

    const clientStore = wrapStore(baseStore, selector => {
      const [showCurrent, setShowCurrent] = useState(didHydrate ?? false)

      useEffect(() => {
        didHydrate = true
        setShowCurrent(true)
      }, [])

      const actual = store(selector)
      const initial = selector(baseState)
      
      return showCurrent ?
        actual :
        initial
    })

    return shallowStore(clientStore)
  }

  const store = create<T>()(state)
  const baseStore = withBaseState(store, baseState)
  return shallowStore(baseStore)
}


function withBaseState<S extends AnyStore, I>(store: S, baseState: I) {
  return Object.defineProperty(store, 'baseState', { value: baseState }) as S & { baseState: I }
}

function wrapStore<S extends BaseStore, R>(store: S, fn: (selector: Parameters<S>[0]) => R) {
  return Object.defineProperties(fn, {
    getState: { value: store.getState },
    setState: { value: store.setState },
    subscribe: { value: store.subscribe },
    getInitialState: { value: store.getInitialState },
    baseState: { value: store.baseState },
  }) as S
}

function shallowStore<S extends BaseStore>(store: S) {
  return wrapStore(store, selector =>
    store(useShallow(selector))
  )
}

export function conditionalStore<S extends BaseStore>(store: S, useEnabled: () => boolean) {
  return wrapStore(store, (...args) => {
    const [selector] = args
    const [data] = useState(() => selector(store.baseState))
    const result = store(...(args as unknown as []))
    return useEnabled() ? result : data
  })
}