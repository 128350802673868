import useClientOnly from './hooks/useClientOnly.js'

export default function lazyLoad<T extends React.ComponentType<any>>(load: () => Promise<{ default: T }>, fallback: React.ReactNode = null) {
  const Component = lazy(load)

  return ((props: React.ComponentProps<T>) => {
    const isClient = useClientOnly()

    return (
      <Suspense fallback={fallback}>
        {isClient && <Component {...props} />}
      </Suspense>
    )
  }) as T
}
