import {
  debounce as _debounce,
  throttle as _throttle,
} from 'lodash-es'

import {
  $name,
  $registry,
  type DebounceSettings,
  type DebouncedFunc,
  type DebouncedFuncLeading,
  type NotLeading,
  type ThrottleSettings,
  type Timer,
  type TimerFn,
  type TimerName,
} from './types.js'

import type TimerRegistry from './TimerRegistry.js'


export default class NamedTimerBuilder<N extends TimerName> {
  [$name]?: N
  [$registry]: TimerRegistry

  constructor(registry: TimerRegistry, name?: N) {
    this[$name] = name
    this[$registry] = registry
  }

  /**
   * Creates a debounced function that delays invoking `fn` until after `wait` milliseconds have elapsed since
   * the last time the debounced function was invoked. The debounced function comes with a `cancel` method to
   * cancel delayed invocations and a `flush` method to immediately invoke them. Provide an `options` object to
   * indicate that `fn` should be invoked on the leading and/or trailing edge of the `wait` timeout. Subsequent
   * calls to the debounced function return the result of the last `fn` invocation.
   *
   * Note: If `leading` and `trailing` options are true, `fn` is invoked on the trailing edge of the timeout only
   * if the the debounced function is invoked more than once during the `wait` timeout.
   *
   * @param fn The function to debounce.
   * @param wait The number of milliseconds to delay.
   * @param options The options object.
   * @param options.maxWait The maximum time `fn` is allowed to be delayed before it’s invoked.
   * @param options.leading Specify invocation on the leading edge of the timeout.
   * @param options.trailing Specify invocation on the trailing edge of the timeout.
   * @return The newly debounced function.
   */
  debounce<T extends UnknownFunction>(fn: T, wait: number | undefined, options: DebounceSettings & NotLeading): DebouncedFunc<T>
  debounce<T extends UnknownFunction>(fn: T, wait?: number, options?: DebounceSettings): DebouncedFuncLeading<T>
  debounce<T extends UnknownFunction>(fn: T, wait?: number, options?: DebounceSettings) {
    const debounced = _debounce(fn, wait, options)
    return Object.assign(debounced, this[$registry].track(debounced.cancel.bind(debounced), this[$name]))
  }


  /**
   * Defers invoking `fn` until the current call stack has cleared.
   * Any additional arguments are provided to `fn` when it is invoked.
   *
   * @param fn The function to defer.
   * @param args The arguments to invoke the function with.
   * @return A timer object with a `cancel` function.
   */
  defer<T extends TimerFn>(fn: T, ...args: Parameters<T>) {
    let canceled = false

    const timer = this[$registry].track(() => canceled = true, this[$name])

    const callback = () => {
      if (!canceled) {
        timer.cancel()
        fn(...args)
      }
    }

    Promise
      .resolve()
      .then(callback)
      .catch(dx.capture)

    return timer
  }

  /**
   * Invokes `fn` after `wait` milliseconds.
   * Any additional arguments are provided to `fn` when it is invoked.
   *
   * @param fn The function to delay.
   * @param wait The number of milliseconds to delay invocation.
   * @param args The arguments to invoke the function with.
   * @return A timer object with a `cancel` function.
   */
  delay<T extends TimerFn>(fn: T, ms: number, ...args: Parameters<T>) {
    let handle: number | null = null

    const timer = this[$registry].track(() => {
      if (handle != null) {
        clearTimeout(handle)
        handle = null
      }
    }, this[$name])


    handle = setTimeout(() => {
      timer.cancel()
      fn(...args)
    }, ms) as unknown as number

    return timer
  }

  /**
   * Invokes `fn` using `requestAnimationFrame` on the next available render time,
   * as determined by the browser.
   *
   * @param fn The function to invoke on the next frame.
   * @return A timer object with a `cancel` function.
   */
  frame(fn: FrameRequestCallback) {
    let handle: number | null = null

    const timer = this[$registry].track(() => {
      if (handle != null) {
        cancelAnimationFrame(handle)
        handle = null
      }
    })

    handle = requestAnimationFrame((time: number) => {
      timer.cancel()
      return fn(time)
    })

    return timer
  }

  /**
   * Repeatedly invokes `fn` every `ms` milliseconds.
   * Any additional arguments are provided to `fn` when it is invoked.
   *
   * @param fn The function to invoke every `ms` milliseconds.
   * @param ms The number of milliseconds to delay in between invocations.
   * @param args The arguments to invoke the function with.
   * @return A timer object with a `cancel` function.
   */
  interval<T extends TimerFn>(fn: T, ms: number, ...args: Parameters<T>) {
    let handle: number | null = null

    const timer = this[$registry].track(() => {
      if (handle != null) {
        clearInterval(handle)
        handle = null
      }
    }, this[$name])

    handle = setInterval(() => {
      fn(...args)
    }, ms) as unknown as number

    return timer
  }

  /**
   * Repeatedly invokes `fn` using `requestAnimationFrame` whenever the browser
   * can schedule it, typically at 60 fps.
   * Any additional arguments are provided to `fn` when it is invoked.
   *
   * @param fn The function to invoke every `ms` milliseconds.
   * @param args The arguments to invoke the function with.
   * @return A timer object with a `cancel` function.
   */
  repeatedly<T extends TimerFn>(fn: T, ...args: Parameters<T>) {
    let handle: number | null = null

    const timer = this[$registry].track(() => {
      if (handle != null) {
        cancelAnimationFrame(handle)
        handle = null
      }
    }, this[$name])

    function next() {
      handle = requestAnimationFrame(next)
      fn(...args)
    }

    handle = requestAnimationFrame(next)
    return timer
  }


  /**
   * Creates a throttled function that only invokes `fn` at most once per every `wait` milliseconds. The throttled
   * function comes with a `cancel` method to cancel delayed invocations and a `flush` method to immediately invoke
   * them. Provide an options object to indicate that `fn` should be invoked on the leading and/or trailing edge
   * of the `wait` timeout. Subsequent calls to the throttled function return the result of the last `fn` call.
   *
   * Note: If `leading` and `trailing` options are true, `fn` is invoked on the trailing edge of the timeout only if
   * the the throttled function is invoked more than once during the `wait` timeout.
   *
   * @param fn The function to throttle.
   * @param wait The number of milliseconds to throttle invocations to.
   * @param options The options object.
   * @param options.leading Specify invoking on the leading edge of the timeout.
   * @param options.trailing Specify invoking on the trailing edge of the timeout.
   * @return Returns the newly throttled function with a `cancel` function.
   */
  throttle<T extends (...args: any) => any>(fn: T, wait: number | undefined, options: ThrottleSettings & NotLeading): Timer<N, DebouncedFunc<T>>
  throttle<T extends (...args: any) => any>(fn: T, wait?: number, options?: ThrottleSettings): Timer<N, DebouncedFuncLeading<T>>
  throttle<T extends (...args: any) => any>(fn: T, wait?: number, options?: ThrottleSettings) {
    const throttled = _throttle(fn, wait, options)
    return Object.assign(throttled, this[$registry].track(throttled.cancel.bind(throttled), this[$name])) as DebouncedFuncLeading<T>
  }
}