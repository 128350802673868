import React from 'react'

type ErrorBoundaryProps = {
  children: React.ReactNode
  fallback?: React.ReactNode | (() => React.ReactNode)
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  state: { error: unknown } = { error: false }

  render() {
    const { children, fallback } = this.props
    const { error } = this.state

    return error ?
      invoke(fallback) :
      children
  }
}