import {
  debounce as _debounce,
  throttle as _throttle,
  type DebouncedFunc,
} from 'lodash-es'

export type {
  ThrottleSettings,
  DebouncedFunc,
  DebounceSettings,
} from 'lodash-es'

export interface DebouncedFuncLeading<T extends (...args: any[]) => any> extends DebouncedFunc<T> {
  (...args: Parameters<T>): ReturnType<T>;
  flush(): ReturnType<T>;
}

export const $registry = Symbol('TimerRegistry.registry')
export const $name = Symbol('TimerRegistry.name')

export type TimerName = string | symbol
export type TimerFn = (...args: unknown[]) => unknown
export type Timer<N extends TimerName, T = EmptyObject> = T & {
  name: N,
  cancel: () => void
}

export type NotLeading = {
  leading: false
}